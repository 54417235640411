<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Use the information provided in the table below to calculate:</p>

      <v-simple-table class="pl-4 mb-6" style="max-width: 580px">
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Quantity}$" />
            </td>
            <td style="text-align: right">
              <stemble-latex content="$\textbf{Enthalpy}$" />
              <stemble-latex content="$\textbf{Change}$" />
              <stemble-latex content="$\text{(kJ/mol)}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Lattice energy of solid ammonium chloride</td>
            <td style="text-align: right">
              <stemble-latex content="$705$" />
            </td>
          </tr>

          <tr>
            <td>Lattice energy of solid ammonium nitrate</td>
            <td style="text-align: right">
              <stemble-latex content="$646$" />
            </td>
          </tr>

          <tr>
            <td>Enthalpy of hydration of gaseous ammonium ion</td>
            <td style="text-align: right">
              <stemble-latex content="$-307$" />
            </td>
          </tr>

          <tr>
            <td>Enthalpy of hydration of gaseous chloride ion</td>
            <td style="text-align: right">
              <stemble-latex content="$-381$" />
            </td>
          </tr>

          <tr>
            <td>Enthalpy of hydration of gaseous nitrate ion</td>
            <td style="text-align: right">
              <stemble-latex content="$-314$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">a) The enthalpy of solution of solid ammonium chloride</p>

      <calculation-input
        v-model="inputs.HsolnNH4Cl"
        prepend-text="$\Delta\text{H}^\circ_{\text{soln}}:$"
        append-text="$\text{kJ/mol}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) The enthalpy of solution of solid ammonium nitrate</p>

      <calculation-input
        v-model="inputs.HsolnNH4NO3"
        prepend-text="$\Delta\text{H}^\circ_{\text{soln}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaPrelab2Q3',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        HsolnNH4Cl: null,
        HsolnNH4NO3: null,
      },
    };
  },
};
</script>
